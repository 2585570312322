<template>
    <v-card flat class="">
        <v-data-table :headers="table.headers" :items="table.items" :options.sync="tableOptions"
            :server-items-length="table.totalItems" :loading="table.loading" dense class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Pengembangan Diri</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="800px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="add()">
                                Tambah Item
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <!-- <v-col cols="4">
                                        <v-text-field v-if="form.editedIndex == -1" v-model="form.editedItem.tanggal"
                                            label="Tanggal" type="date" dense outlined hide-details></v-text-field>
                                        <v-text-field v-else readonly v-model="form.editedItem.tanggal" label="Tanggal"
                                            type="date" dense outlined hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-if="form.editedIndex == -1" v-model="form.editedItem.jam"
                                            label="Jam" type="time" dense outlined hide-details></v-text-field>
                                        <v-text-field v-else readonly v-model="form.editedItem.jam" label="Jam" type="time"
                                            dense outlined hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select v-model="form.editedItem.pembimbing_id" outlined hide-details dense
                                            label="Pembimbing :" :items="form.pembimbings" item-value="user_id"
                                            item-text="nama"></v-select>
                                    </v-col> -->
                                    <v-col cols="4">
                                        <v-text-field v-model="form.editedItem.nama" label="Nama:" dense outlined
                                            hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="form.editedItem.nip" label="NIP:" dense outlined
                                            hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="form.editedItem.masa_kerja" type="number"
                                            label="Masa Kerja (Tahun) :" dense outlined hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete v-model="form.editedItem.id_dokdiknis" :items="form.dokdikniss"
                                            outlined dense label="Dokdiknis/Pembimbing :" item-text="nama"
                                            item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="form.editedItem.ijazah" label="No Ijazah :" dense outlined
                                            hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="form.editedItem.sip" label="No SIP :" dense outlined
                                            hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="form.editedItem.str" label="No STR :" dense outlined
                                            hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <a v-if="form.editedItem.sertifikat != ''" :href="form.editedItem.file_url"
                                            target="_blank">Lihat File</a>
                                        <input type="file" label="Sertifikat:" @change="fileChange" ref="file" />
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" @click="save()">
                                    Simpan
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- <v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" @click="closeDelete">Cancel</v-btn>
								<v-btn color="blue darken-1" @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog> -->
                </v-toolbar>
            </template>
            <!-- <template v-slot:item.waktu="{ item }">
                {{ formatDate(item.tanggal) }} {{ formatDate(item.jam) }}
            </template> -->
            <template v-slot:item.sertifikat="{ item }">
                <a :href="item.file_url" target="_blank">Lihat File</a>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="editItem(item)">
                    {{ icons.mdiPencil }}
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn x-small color="primary" @click="getDataFromApi()">
                    Reload
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil } from '@mdi/js'
import UploadDokumenRegistrasi from '@/components/upload/UploadDokumenRegistrasi.vue'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
    components: {
        UploadDokumenRegistrasi,
    },
    data() {
        return {
            praktik_id: this.$route.params.praktik_id,
            data: {
                nama_institusi: '',
				nama_prodi: '',
                profesi: '',
                stase: '',
                tanggal_awal: '',
                tanggal_akhir: '',
            },
            icons: {
                mdiDelete,
                mdiPencil,
            },
            tableOptions: {},
            table: {
                loading: true,
                headers: [
                    { text: 'No', align: 'start', sortable: false, value: 'no', },
                    { text: 'Nama', value: 'nama', sortable: false },
                    { text: 'NIP', value: 'nip', sortable: false },
                    { text: 'Masa Kerja', value: 'masa_kerja', sortable: false },
                    { text: 'Ijazah', value: 'ijazah', sortable: false },
                    { text: 'SIP', value: 'sip', sortable: false },
                    { text: 'STR', value: 'str', sortable: false },
                    { text: 'Sertifikat', value: 'sertifikat', sortable: false },
                    { text: '', value: 'actions', sortable: false },
                ],
                items: [],
                totalItems: 0,
            },
            dialog: false,
            form: {
                editedIndex: -1,
                editedItem: {
                    nama: '',
                    nip: '',
                    masa_kerja: '',
                    id_dokdiknis: '',
                    ijazah: '',
                    sip: '',
                    str: '',
                    sertifikat: '',
                },
                defaultItem: {
                    nama: '',
                    nip: '',
                    masa_kerja: '',
                    id_dokdiknis: '',
                    ijazah: '',
                    sip: '',
                    str: '',
                    sertifikat: '',
                },
                dokdikniss: [],
            },
        }
    },
    watch: {
        tableOptions: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    computed: {
        formTitle() {
            return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
        },
    },
    created() {
        this.getPilihanDokdiknis()
    },
    methods: {
        fileChange() {
            this.form.editedItem.sertifikat = this.$refs.file.files[0];
        },
        save() {
            if (this.form.editedIndex > -1) {
                // const fmData = new FormData()
                // fmData.append('id', this.form.editedItem.id)
                // fmData.append('nama', this.form.editedItem.nama)
                // fmData.append('nip', this.form.editedItem.nip)
                // fmData.append('masa_kerja', this.form.editedItem.masa_kerja)
                // fmData.append('dokdiknis', this.form.editedItem.id_dokdiknis)
                // fmData.append('ijazah', this.form.editedItem.ijazah)
                // fmData.append('sip', this.form.editedItem.sip)
                // fmData.append('str', this.form.editedItem.str)
                // fmData.append('sertifikat', this.form.editedItem.sertifikat)
                // axiosPostAuth('api/Pembimbing/addPengembangan', fmData)
                //     .then(response => {
                //         if (response.code === 200) {
                //             this.getDataFromApi()
                //             this.dialog = false
                //         }
                //         else {
                //             alert(response.message)
                //         }
                //     })
                //     .catch(error => {
                //         alert(error)
                //     })
            } else {
                const fmData = new FormData()
                fmData.append('nama', this.form.editedItem.nama)
                fmData.append('nip', this.form.editedItem.nip)
                fmData.append('masa_kerja', this.form.editedItem.masa_kerja)
                fmData.append('dokdiknis', this.form.editedItem.id_dokdiknis)
                fmData.append('ijazah', this.form.editedItem.ijazah)
                fmData.append('sip', this.form.editedItem.sip)
                fmData.append('str', this.form.editedItem.str)
                fmData.append('sertifikat', this.form.editedItem.sertifikat)
                axiosPostAuth('api/Pembimbing/addPengembangan', fmData)
                    .then(response => {
                        if (response.code === 200) {
                            this.getDataFromApi()
                            this.dialog = false
                        }
                        else {
                            alert(response.message)
                        }
                    })
                    .catch(error => {
                        alert(error)
                    })
            }
        },
        getDataFromApi() {
            const { page, itemsPerPage } = this.tableOptions
            const fmData = new FormData()
            fmData.append('page', page)
            fmData.append('itemsPerPage', itemsPerPage)
            fmData.append('search', '')
            axiosPostAuth('api/Pembimbing/getPengembangan_datatable', fmData)
                .then(response => {
                    this.table.items = response.data
                    this.table.totalItems = response.total
                    this.table.loading = false
                })
                .catch(error => {
                    alert(error)
                })
        },
        add() {
            this.form.editedItem = Object.assign({}, this.form.defaultItem)
            this.form.editedIndex = -1
        },
        editItem(item) {
            console.log(item);
            this.form.editedIndex = this.table.items.indexOf(item)
            this.form.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            const conf = confirm("Apakah yakin akan dihapus?")
            if (conf) {
                const fmData = new FormData()
                fmData.append('id', item.id)
                axiosPostAuth('api/Praktik/deleteUserPraktik', fmData)
                    .then(response => {
                        if (response.code === 200) {
                            this.getDataFromApi()
                        }
                        else {
                            alert(response.message)
                        }
                    })
                    .catch(error => {
                        alert(error)
                    })
            }
        },
        getPilihanDokdiknis() {
            axiosGetAuth(`api/RegisterLanjutan/getPilihanDokdiknis`)
                .then(response => {
                    if (response.code === 200) {
                        this.form.dokdikniss = response.datas
                    } else {
                        alert(response.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>